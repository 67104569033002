import React from 'react';
import { WorkField } from '../sdk/entities/WorkField';
import { graphql } from 'gatsby';
import { SEO } from '../components/seo/seo';
import { Layout } from '../components/layout/layout';
import PageLinks from '../components/page-links/page-links';
import { Position } from '../sdk/entities/Position';
import { Vacancy } from '../sdk/entities/Vacancy';
import VacanciesList from '../components/vacancies-list/vacancies-list';
import VacancyFilterContainer from '../components/vacancy-filter-container/vacancy-filter-container';
import DynamicContent from '../components/dynamic-content/dynamic-content';
import NoVacancyAdvice from '../components/no-vacancy-advice/no-vacancy-advice';

export type WorkFieldPageProps = {
  data: {
    workField: WorkField & { text: string };
    allPosition: {
      nodes: Position[];
    };
    allVacancy: {
      nodes: Vacancy[];
    };
    allWorkField: {
      nodes: WorkField[];
    };
  };
};

const WorkFieldPage: React.FC<WorkFieldPageProps> = ({ data }) => {
  const hasVacancies = data.allVacancy.nodes.length > 0;

  return (
    <main>
      <Layout
        breadcrumbItems={[
          { title: data.workField.name, href: `/${data.workField.slug}/` },
        ]}
        hideFilter={true}
      >
        <h1 className="text-[32px]">Vagas para {data.workField.name}</h1>

        <div className="my-16">
          <VacancyFilterContainer
            fullWidth
            filters={{
              workFieldId: data.workField.id,
            }}
          />
        </div>

        {!hasVacancies && (
          <div className="my-16">
            <NoVacancyAdvice workFieldName={data.workField.name} />
          </div>
        )}

        {data.allVacancy.nodes.length > 0 && (
          <div className={'my-16'}>
            <VacanciesList
              title={`Vagas Recentes na Área de ${data.workField.name}`}
              vacancies={data.allVacancy.nodes}
              queryParams={{
                workFieldId: data.workField.id,
                workFieldIdName: data.workField.name,
              }}
            />
          </div>
        )}

        {data.allPosition.nodes.length > 0 && (
          <div className={'my-16'}>
            <PageLinks
              defaultIndex={1}
              data={[
                {
                  title: 'Áreas',
                  links: data.allWorkField.nodes.map(workField => ({
                    title: workField.name,
                    url: `/${workField.slug}/`,
                  })),
                },
                {
                  title: 'Cargos',
                  links: data.allPosition.nodes.map(position => ({
                    title: position.name,
                    url: `/${data.workField.slug}/${position.slug}/`,
                  })),
                },
              ]}
            />
          </div>
        )}

        <div className="my-16">
          <DynamicContent text={data.workField.text} />
        </div>
      </Layout>
    </main>
  );
};

export const query = graphql`
  query ($id: String!) {
    workField(_id: { eq: $id }) {
      id
      name
      slug
      text
    }
    allPosition(filter: { workFieldId: { eq: $id } }, sort: { name: ASC }) {
      nodes {
        name
        slug
      }
    }
    allWorkField(sort: { name: ASC }) {
      nodes {
        id
        name
        slug
      }
    }
    allVacancy(
      sort: { createdAt: DESC }
      filter: { active: { eq: true }, position: { workFieldId: { eq: $id } } }
      limit: 5
    ) {
      nodes {
        id
        _id
        name
        maxRemuneration
        confidential
        contractModel
        description
        remuneration
        remunerationModel
        slug
        position {
          slug
          workField {
            slug
          }
        }
        workplace {
          name
          address {
            city
            complement
            country
            district
            lat
            lng
            number
            postalCode
            referencePoint
            state
            street
          }
          image
        }
        officeDays
        image {
          childImageSharp {
            gatsbyImageData(
              width: 40
              height: 40
              transformOptions: { fit: COVER }
              quality: 80
            )
          }
        }
      }
    }
  }
`;

export const Head: React.FC<WorkFieldPageProps> = ({ data }) => (
  <SEO
    title={`Vagas para ${data.workField.name}`}
    description={`Veja as vagas disponíveis para a área de ${data.workField.name}`}
    pathname={`/${data.workField.slug}/`}
    breadcrumbItems={[
      { title: data.workField.name, href: `/${data.workField.slug}/` },
    ]}
  />
);

export default WorkFieldPage;
