import React, { useEffect, useMemo, useState } from 'react';
import * as classes from './page-links.module.scss';

interface PageLinksProps {
  data: {
    title: string;
    links: {
      title: string;
      url: string;
    }[];
  }[];
  defaultIndex?: number;
}

const PageLinks = (props: PageLinksProps) => {
  const { data, defaultIndex } = props;
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex || 0);

  const scrollToSection = () => {
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    scrollToSection();
  }, []);

  const links = useMemo(() => {
    return data[selectedIndex].links;
  }, [selectedIndex]);

  return (
    <div id={`page-links`} className={classes.container}>
      <div className={classes.options}>
        <div className={classes.line} />
        {data.map((item, index) => (
          <button
            key={item.title}
            className={`${selectedIndex === index ? classes.active : ''}`}
            onClick={() => setSelectedIndex(index)}
          >
            {item.title}
            <div
              className={`
              ${selectedIndex === index ? classes.active : ''}
              ${classes.line}
              ${classes.lineButton}
            `}
            />
          </button>
        ))}
        <div className={classes.line} />
      </div>

      <ul className="list-inside list-none sm:columns-3 mt-20">
        {links.map(link => (
          <li key={link.title + link.url} className="py-1">
            <a
              href={link.url}
              className={`hover:underline text-[#3670A6] font-semibold mb-5`}
            >
              <span>{link.title}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PageLinks;
